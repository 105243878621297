<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="title"
        :subtitle="subTitle"
        :hide-buttons="true"
    >
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de notificacion de
          {{ module }}
        </vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <label for="recipient" class="w-full select-large">Destinatario</label>
          <vs-input
              id="recipient"
              name="recipient"
              v-model="notificationSettingsModel.recipient"
              v-validate="'required|email'"
              class="w-full"
              autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("recipient") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros Modulo</vs-divider>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="recipient" class="w-full select-large">Categorías</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.entry.categories"
              :options="categories"
              :reduce="item => item.id"
              label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="recipient" class="w-full select-large">Departamentos</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.entry.state"
              :options="states"
              :reduce="item => item.id"
              label="name"
              @input="getCities(notificationSettingsModel.notifications_rules.entry.state)"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="recipient" class="w-full select-large">Ciudades</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.entry.city"
              :options="cities"
              :reduce="item => item.id"
              label="name"
          />
        </div>
      </div>
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros Formulario</vs-divider>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <label for="recipient" class="w-full select-large">Departamentos</label>
          <v-select
              multiple
              v-model="notificationSettingsModel.notifications_rules.form_data.states"
              :options="statesPrefilledList"
              :reduce="item => item.id"
              label="label"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToBack">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateNotificationSettings" :disabled="!validateForm">
                Guardar
              </vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import NotificationSettingsClient from "../../utils/notificationSettingsClient";
  import PrefilledListsClient from "../../utils/prefilledListsClient"
  import Notifier from "./../../utils/notification";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()
  const PREFILLED_LISTS_CLIENT = new PrefilledListsClient()
  const STATES_CLIENT = new CoreClient("states");
  const CITIES_CLIENT = new EntriesClient("cities");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("salvages");

  const requiredFields = ["recipient"];

  let dict = requiredFields.reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue] = {required: "* Este campo es requerido"};
        return previousValue
      },
      {}
  );

  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        title: "",
        subTitle: "",
        module: "",
        entry_type: "",
        cities: [],
        states: [],
        statesPrefilledList: [],
        categories: [],
        notificationSettingsModel: {
          recipient: "",
          notifications_rules: {
            entry: {
              category: null,
              state: null,
              city: null,
            },
            form_data: {
              states: null,
            },
          },
        },
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.getPageTitle(true);
        this.loadNotificationSettingData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
        });
      } else {
        this.getPageTitle(false);
        this.getStates(false)
        this.getCategories(false)
        this.getStatesPrefilledList(false)
            .then(() => {
              this.$vs.loading.close();
            })
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
            && requiredFields.reduce((accumulator, currentValue) => {
              return accumulator && this.notificationSettingsModel[currentValue] !== null;
            });
      }
    },

    methods: {

      getPageTitle(update) {
        this.title = update ? `Editar notificación de Salvamentos` : `Crear notificacion de Salvamentos`
        this.subTitle = update ? `Formulario edición notificación de Salvamentos` : `Formulario creación notificación de Salvamentos`
        this.module = "Salvamentos"
        this.entry_type = "salvages"
      },

      async getStates(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await STATES_CLIENT.all()
            .then(response => {
              if (showLoading) this.$vs.loading.close();
              this.states = response.data;
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async getStatesPrefilledList(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await PREFILLED_LISTS_CLIENT.getStates()
            .then(response => {
              if (showLoading) this.$vs.loading.close();
              this.statesPrefilledList = response.data;
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async getCities(state, showLoading = true) {
        if (state !== null && state !== undefined && state !== "") {
          if (showLoading) this.$vs.loading();

          await CITIES_CLIENT.all({queryStrings: {state_id: state}})
              .then(response => {
                this.cities = response.data;
                if (showLoading) this.$vs.loading.close();
              })
              .catch(error => {
                if (showLoading) this.$vs.loading.close();
                notifier.notification("error");
              });
        } else {
          this.cities = [];
          this.notificationSettingsModel.notifications_rules.entry.city = null;
        }
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_CLIENT.categories({
          queryStrings: {representation_type: "simple"}
        })
            .then(response => {
              if (showLoading) this.$vs.loading.close();
              this.categories = response.data;
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      },

      async loadNotificationSettingData(id) {
        await NOTIFICATION_SETTINGS_CLIENT.retrieve(this.entry_type, id)
            .then(response => {
              this.getCategories(false)
              this.getStatesPrefilledList(false)
              this.getStates(false)
                  .then(() => {
                    this.getCities(response.data.notifications_rules.entry.state, false)
                        .then(() => {
                          Object.assign(this.notificationSettingsModel, response.data);
                        })
                  })
            })
            .catch(error => {
              notifier.notification("error");
            });
      },

      async createOrUpdateNotificationSettings() {
        if (this.notificationSettingsModel.id !== null && this.notificationSettingsModel.id !== undefined && this.notificationSettingsModel.id !== "") {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.update(
              this.notificationSettingsModel, this.entry_type, this.$route.params.id
          )
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("updated");
                this.goToBack();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        } else {
          this.$vs.loading();

          await NOTIFICATION_SETTINGS_CLIENT.create(this.notificationSettingsModel, this.entry_type)
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("created");
                this.goToBack();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        }
      },

      goToBack() {
        this.$router.push({name: "notification-settings", params: {entry_type: this.entry_type}});
      },

      cleanModel() {
        let exclude = ["entry_type", "id", "unix_created_at", "unix_updated_at"]

        Object.keys(this.notificationSettingsModel).forEach(key => {
          if (exclude.includes(key)) {
            delete this.notificationSettingsModel[key];
          }
        })
      },
    },

    components: {
      FormWizard,
      TabContent,
      "v-select": vSelect,
    }
  };
</script>